import ImageConstant from "../utils/imageConstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Constants from "../utils/constant";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ExecutiveTeam = () => {

    return (
        <div className="wrapper">
            <h2>Owner</h2>
            <hr/>
            <div className="members">
                <div className="team-mem">
                    <img src={ImageConstant.sumi}alt="CEO"className="Ceo"/>
                    
                </div>
                <h4>Mr. Sachin </h4>
            </div>
        </div>
    );

};
export default ExecutiveTeam;
