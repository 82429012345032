import ImageConstant from "../utils/imageConstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
} from "@fortawesome/free-solid-svg-icons";
import Constants from "../utils/constant";
import "react-toastify/dist/ReactToastify.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Logos = () => {
    return (
      <>
      <section className="Logos section" id="Logos">
            <div className="Logos__container container grid">
                <AnimationOnScroll animateIn="animate__fadeInLeft">
                <img
                    src={ImageConstant.OKX}
                    alt="logo"
                    className="Logos__img"
                />
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeInLeft">
                <img
                    src={ImageConstant.unisat}
                    alt="logo"
                    className="Logos__img"
                />
                </AnimationOnScroll>

                <AnimationOnScroll animateIn="animate__fadeInLeft">
                <img
                   src={ImageConstant.LBank}
                   alt="logo"
                   className="Logos__img"
                />
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeInLeft">
                <img
                    src={ImageConstant.Bingx}
                    alt="logo"
                    className="Logos__img"
                />
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeInRight">
                <img
                    src={ImageConstant.GateIO}
                    alt="logo"
                    className="Logos__img"
                />
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeInRight">
                <img
                    src={ImageConstant.bit4x}
                    alt="logo"
                    className="Logos__img"
                />
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeInRight">
                <img
                    src={ImageConstant.BitMart}
                    alt="logo"
                    className="Logos__img"
                />
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeInRight">
                <img
                    src={ImageConstant.WOOX}
                    alt="logo"
                    className="Logos__img"
                />
                </AnimationOnScroll>
            </div>
      </section>
      </>
    );
  };
  export default Logos;