import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import "animate.css/animate.min.css";

const App = () => {
  return (
    <> 
      <Home/>

      <ToastContainer />
    </>
  );
};

export default App;
