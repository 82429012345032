import ImageConstant from "../utils/imageConstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import Constants from "../utils/constant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Particle from "./Particle";
import "../css/particle.css";

const Program = () => {
    return (
      <>
      <section className="Program section" id="Program">
        <div className="container">
          <div className="section__data">
            <AnimationOnScroll animateIn="animate__bounceIn">
              <h2 className="section__subtitle">Our Vision To</h2>
            </AnimationOnScroll>
            <div className="section__titles">
              <AnimationOnScroll animateIn="animate__fadeInDown">
                <h1 className="section__title-border">Build Your</h1>
                <h1 className="section__title">Future</h1>
              </AnimationOnScroll>
            </div>
          </div>

          <div className="Program__container grid">
            <article className="Program__card">
              <div className="Program__shape">
                <AnimationOnScroll animateIn="animate__rotateIn">
                  <img
                    src={ImageConstant.Mining}
                    alt="Program "
                    className="Program__img"
                  />
                </AnimationOnScroll>
              </div>

              <h3 className="Program__title"> Mining App</h3>

              <p className="Program__discription">
                Mining App for users so that they can mine more ASRL coin.
              </p>

              <a href="#" className="Program__button">
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </article>

            <article className="Program__card">
              <div className="Program__shape">
                <AnimationOnScroll animateIn="animate__rotateIn">
                  <img
                    src={ImageConstant.ai}
                    alt="Program"
                    className="Program__img"
                  />
                </AnimationOnScroll>
              </div>

              <h3 className="Program__title"> Ai Tools</h3>

              <p className="Program__discription">
                AI tools to make work easier and simpler for others.
              </p>

              <a href="#" className="Program__button">
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </article>

            <article className="Program__card">
              <div className="Program__shape">
                <AnimationOnScroll animateIn="animate__rotateIn">
                  <img
                    src={ImageConstant.crypto}
                    alt="Program"
                    className="Program__img"
                  />
                </AnimationOnScroll>
              </div>

              <h3 className="Program__title">ASRL Wallet</h3>

              <p className="Program__discription">
                To Buy and Sell ASRLcoin directly from website. For ease
                operation in future.
              </p>

              <a href="#" className="Program__button">
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </article>

            <article className="Program__card">
              <div className="Program__shape">
                <AnimationOnScroll animateIn="animate__rotateIn">
                  <img
                    src={ImageConstant.cryptoTube}
                    alt="Program"
                    className="Program__img"
                  />
                </AnimationOnScroll>
              </div>

              <h3 className="Program__title">Crypto Tube</h3>

              <p className="Program__discription">
                Crypto news BroadCasting. So that You will get Crypto Related
                News Daily.
              </p>

              <a href="#" className="Program__button">
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </article>
          </div>
        </div>
      </section>
      </>
    );
  };
  export default Program;
  