import ImageConstant from "../utils/imageConstant";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faYoutube,
  faWhatsapp,
  faSquareXTwitter,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import Logos from "./Logos";
import Program from "./Program";
import Blockchain from "./Blockchain";
import Phase from './Phase';
import Method1 from "./Method1";
import Method2  from "./Method2";
import ExecutiveTeam from "./ExecutiveTeam";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Particle from "./Particle";
import Footer from "./Footer";
import "../css/particle.css";
import Sidebar from "./Sidebar";


const Home = () => {
  const [copied, setCopied] = useState(false);
  const [navIconHeight, setNavIconHeight] = useState(35)
  const handleCopyClick = (text) => {
    if (window.isSecureContext) {
      navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2500);
    } else {
      toast.error(`Failed to copy code, Please copy manually.`, {
        toastId: "copy",
      });
    }
  };

  const handleNavIconClick = (e) => {
    
  }

  // useEffect(()=>{
  //   const onScrollEvent = (e) => {
  //     if(window.scrollY > 0){
  //       setNavIconHeight(25)
  //     }else{
  //       setNavIconHeight(35)
  //     }
  //   }

  //   window.addEventListener("scroll", onScrollEvent);

  //   return () => {
  //     window.removeEventListener("scroll", onScrollEvent);
  //   }
  // }, [])

  return (
    <>
    <Sidebar/>
     <Particle/> 
      <section className="Home" id="Home">
        <div className="Home__shape-small"></div>

        <div className="Home__shape-big-2"></div>
        <img src={ImageConstant.shapeBg} alt="" className="Home__shape-bg" />
        <div className="Home__container container">
          <div className="Home__info">
            <h1 className="Home__title">
              <span>ASRL</span>
              <br />
              Lets Build A Bright<br />
              Future Together
            </h1>
            <p className="Home__description">
              For Extra Benifite And News Updates
            </p>
            <p className="Home__description">
              Follow Us:
            </p>
            <div className="Follow">
              <li className="Whatsapp"><a href="https://whatsapp.com/channel/0029VaAqMtgKrWQs2DT1Qz3p"><FontAwesomeIcon icon={faWhatsapp} /></a></li>
              <li className="Twitter"><a href="https://twitter.com/asrlcoin"><FontAwesomeIcon icon={faXTwitter} /></a></li>

            </div>
          </div>
          <div className="Home__group">
            <div className="Home__images">
              <div className="Home__img-eth">
              <img
                    src={ImageConstant.Gif}
                    alt="logo"
                />
              </div>
            </div>

            <div className="Home__data">
              <div>
                <h2 className="Home__data-number">21M</h2>
                <span className="Home__data-subtitle">supply</span>
              </div>
              <div>
                <h2 className="Home__data-number">6</h2>
                <span className="Home__data-subtitle">Partners</span>
              </div>
            </div>
          </div>
        </div>
      </section>
     <Logos/>
     <Program/>
     <Blockchain/>
     <Phase/>
     <Method1/>
     <Method2/>
     <ExecutiveTeam/>
     <Footer/>
    </>
  );
};

export default Home;
