import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/particle.css";

const Phase = () => {
    return (
      <>
      <section className="Phase" id="Phase">
        <div className="container">
          <div className="section__data">
            <h1 className="section__subtitle">Phases</h1>
          </div>
          <div className="Phase__container grid">
            <article className="Phase__card">
              <header className="Phase__header">
                <h1 className="Phase__title">Phase 1</h1>
              </header>
              <ul className="Phase__list">
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>Website Launch
                </li>
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>WhitePaper Launch
                </li>
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>CoinListing
                </li>
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>1,000+ Twitter
                  Followers
                </li>
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>5,000+ Holders
                </li>
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>Marketing
                  Campaign
                </li>
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>Whitelist
                  presale
                </li>
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>CoinGecko
                  Listing
                </li>
              </ul>
            </article>
            <article className="Phase__card">
            <header className="Phase__header">
                <h1 className="Phase__title">Phase 2</h1>
              </header>
              <ul className="Phase__list">
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>Mining App launch
                </li>
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>CoinListing
                </li>
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>5,000+ Twitter
                  Followers
                </li>
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>10,000+ Holders
                </li>
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>Special Air Drop
                </li>
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>Xchange listing
                </li>
              </ul>
            </article>
            <article className="Phase__card">
              <header className="Phase__header">
                <h1 className="Phase__title">Phase 3</h1>
              </header>
              <ul className="Phase__list">
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>Blockchain
                  Development
                </li>
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>Metaverse 
                </li>
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>ASRL Debit Card
                </li>
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>10,000+ Twitter
                  Followers
                </li>
                <li className="Phase__bullets">
                  <FontAwesomeIcon icon={faCircle} size="2xs"/>50,000+ Holders
                </li>
              </ul>
            </article>
          </div>
        </div>
      </section>
      </>
    );
  };
  export default Phase;