const ImageConstant = {
    metamask: require("../images/metamask.jpg"),
    laptop1: require("../images/unisat main screen.png"),
    Banner: require("../images/arsl banner3.png"),
    laptop2: require("../images/buy panel.png"),
    BitMart: require("../images/BitMart.png"),
    LBank: require("../images/lbank.png"),
    Bingx: require("../images/Bingx-website-2.jpg"),
    GateIO: require("../images/GateIO.png"),
    OKX: require("../images/images.png"),
    ai: require("../images/ai.png"),
    crypto: require("../images/crypto-wallet (1).png"),
    bit4x: require("../images/Bit-Forex.png"),
    Mining: require("../images/data-mining (1).png"),
    cryptoTube: require("../images/video-call.png"),
    WOOX: require("../images/WOOX.png"),
    ARSLbanner: require("../images/ARSL BANNER.png"),
    unisat: require("../images/Unisat-Research-scaled (1).jpg"),
    aiImage: require("../images/ai-image.png"),
    qrcode: require("../images/qr code.png"),
    sumi: require("../images/sumianimated.jpg"),
    Gif: require("../images/arsl.gif")
}
export default ImageConstant;