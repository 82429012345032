import ImageConstant from "../utils/imageConstant";
import { ReactDOM } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faYoutube,
  faWhatsapp,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-scroll";
import { toast } from "react-toastify";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="background">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="100%"
          height="100%"
          viewBox="0 0 1600 900"
        >
          <defs>
            <linearGradient id="bg" x2="0%" y2="100%">
              <stop
                offset="0%"
                style={{stopColor: "rgba(53, 127, 242, 0.6)"}}
              ></stop>
              <stop
                offset="100%"
                style={{stopColor: "rgba(38, 89, 190, 0.06)"}}
              ></stop>
            </linearGradient>
            <path
              id="wave"
              fill="url(#bg)"
              d="M-363.852,502.589c0,0,236.988-41.997,505.475,0
      s371.981,38.998,575.971,0s293.985-39.278,505.474,5.859s493.475,48.368,716.963-4.995v560.106H-363.852V502.589z"
            />
          </defs>
          <g>
            <use xlinkHref="#wave" opacity=".3">
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                dur="8s"
                calcMode="spline"
                values="270 230; -334 180; 270 230"
                keyTimes="0; .5; 1"
                keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                repeatCount="indefinite"
              />
            </use>
            <use xlinkHref="#wave" opacity=".6">
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                dur="6s"
                calcMode="spline"
                values="-270 230;243 220;-270 230"
                keyTimes="0; .6; 1"
                keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                repeatCount="indefinite"
              />
            </use>
            <use xlinkHref="#wave" opacty=".9">
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                dur="4s"
                calcMode="spline"
                values="0 230;-140 200;0 230"
                keyTimes="0; .4; 1"
                keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                repeatCount="indefinite"
              />
            </use>
          </g>
        </svg>
        </div>
        <section className="footer__content">
          <ul className="apps">
            <li className="Whatsapp"><a href="https://whatsapp.com/channel/0029VaAqMtgKrWQs2DT1Qz3p"><FontAwesomeIcon icon={faWhatsapp} /></a></li>
            <li className="Twitter"><a href="https://twitter.com/asrlcoin"><FontAwesomeIcon icon={faXTwitter} /></a></li>
          </ul>
          <ul className="links">
            <li><Link to="Home" spy={true} smooth={true} offset={-100} duration={100}>Home</Link></li>
            <li><Link to="Blockchain" spy={true} smooth={true} offset={-100} duration={100}>Blockchain</Link></li>
            <li><Link to="Phase" spy={true} smooth={true} offset={0} duration={100}>Phase</Link></li>
            <li><Link to="Method1" spy={true} smooth={true} offset={-100} duration={100}>Buy</Link></li>
            <li><Link to="Program" spy={true} smooth={true} offset={75} duration={100}>Program</Link></li>
          </ul>
          <p className="legal">© 2024 All Rights Reserved</p>
        </section>
      </div>
    </>
  );
};
export default Footer;
