import ImageConstant from "../utils/imageConstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Constants from "../utils/constant";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Method2= () => {
  const [copied, setCopied] = useState(false);
  const handleCopyClick = (text) => {
    if (window.isSecureContext) {
      navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2500);
    } else {
      toast.error(`Failed to copy code, Please copy manually.`, {
        toastId: "copy",
      });
    }
  };

    return (
      <>
      <section className="Method1 section" id="Method1">
        <div className="Method1__overflow">
          <div className="Method1__container container grid">
            <div className="Method1__content">
              <div className="section__data">
                <div className="section__title">
                  <h1 className="section__title-border">
                    OFFER!
                  </h1>
                </div>
              </div>
              <p className="Method1__description">
                To Join Special Offer:
              </p>
              <p className="Method1__description">
                Step1: Send Your USDT BEP-20 To The Address Giver Bellow
              </p>
              
              <p className="Method1__description">
                Step2: Fill Google Form given Bellow.
              </p>
              <p className="Method1__description">
                (Deposite Address Will Be From Brc-20 Network.)
              </p>
              <p className="Method1__description">
                Your Coin Will Be Send Under 24Hours
              </p>
              <div className="Blockchain__copy-text">
                <span className="text">{Constants.BlockchainCode}</span>

                <button
                  onClick={() => handleCopyClick(Constants.B)}
                  className=""
                >
                  <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
                </button>
              </div>
              <div>
                <a href="https://forms.gle/jxkJpjaGxdUngBZu8" className="Buy__Form">
                 Google Form 
                </a>
              </div>
            </div>
            <div className="Banner">
              <img
                src={ImageConstant.ARSLbanner}
                alt="Mobile Phone"
                className="Method1__img"
              />
            </div>
            <div className="Method1__triangle Method1__triangle-1"></div>
          </div>
        </div>
      </section>
      </>
    );
};
export default Method2;