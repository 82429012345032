import { faBars, faClose, faHome, faMoneyCheckDollar, faClipboardList, faCircleInfo, faRectangleList} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-scroll";
import '../css/sidebar.css'
import { useState } from "react";
const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    return <>
        <div className={`sidemenu-floating-icon-container ${isOpen ? "open" : ""}`} onClick={()=>{
            setIsOpen(true)
        }}>
            <FontAwesomeIcon icon={faBars} className={`side-menu-bars`} />
        </div>
        <div className={`sidebar-menu ${isOpen ? "open" : ""}`}>
            <div className="side-menu-close" onClick={()=>{
                setIsOpen(false)
            }}>
                <FontAwesomeIcon icon={faClose} width={60} height={60} />

            </div>
            <ul>
                <li>
                   <Link to="Home" spy={true} smooth={true} offset={-100} duration={100}><FontAwesomeIcon icon={faHome} /></Link> 
                </li>
                <li>
                    <Link to="Program" spy={true} smooth={true} offset={75} duration={100}><FontAwesomeIcon icon={faClipboardList} /></Link> 
                </li>
                <li>
                    <Link to="Blockchain" spy={true} smooth={true} offset={-100} duration={100}><FontAwesomeIcon icon={faCircleInfo} /></Link>
                </li>
                <li>
                    <Link to="Phase" spy={true} smooth={true} offset={0} duration={100}><FontAwesomeIcon icon={faRectangleList} /></Link>
                </li>
                <li>
                    <Link to="Method1" spy={true} smooth={true} offset={-100} duration={100}><FontAwesomeIcon icon={faMoneyCheckDollar} /></Link>
                </li>
            </ul>
        </div>
    </>
}

export default Sidebar;