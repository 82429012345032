import ImageConstant from "../utils/imageConstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Constants from "../utils/constant";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Method1 = () => {
  const [copied, setCopied] = useState(false);
  const handleCopyClick = (text) => {
    if (window.isSecureContext) {
      navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2500);
    } else {
      toast.error(`Failed to copy code, Please copy manually.`, {
        toastId: "copy",
      });
    }
  };

    return (
      <>
      <section className="Method1 section" id="Method1">
        <div className="Method1__overflow">
          <div className="Method1__container container grid">
            <div className="Method1__content">
              <div className="section__data">
                <div className="section__title">
                  <h1 className="section__title-border">
                    Based on BRC20 Blockchain
                  </h1>
                </div>
              </div>
              <p className="Method1__description">
              BRC-20 is an experimental token standard that enables the minting and transferring of fungible tokens via the Ordinals protocol on the Bitcoin Method1
              </p>
              <div className="Method1__copy">

                <a href="https://unisat.io/" className="Buy__Form">
                Click Here
                </a>
              </div>
            </div>

            <div>
              <img
                src={ImageConstant.laptop1}
                alt="Mobile Phone"
                className="Method2__img"
              />
              <div className="Blockchain__triangle Blockchain__triangle-1"></div>
            </div>
          </div>
        </div>
      </section>
      </>
    );
  };
  export default Method1;
  